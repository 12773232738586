import React, { Component } from "react"
import "./style.scss"
import FormButton from "@components/_atoms/button_form_input_submit"
import InputLabelState from "@components/_molecules/input_label_state"
import * as data from "@data/pledge"

const RadioWithCheck = ({ children, data }) => (
  <div className="checkmark">
    <input
      id={data.id}
      type="radio"
      name={data.name}
      value={data.value}
      checked={data.checked}
    />
    <label htmlFor={data.id}>
      <span>{children}</span>
    </label>
  </div>
)

export default RadioWithCheck
