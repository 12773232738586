import React from "react"

import SEO from "@components/seo"
import * as data from "@data/pledge"
import Layout from "@components/layout"
import HeaderAndText from "@components/header_with_text"
import BackButton from "@components/back_button"
import EmploymentForm from "@components/_molecules/form_employment_status_pledge"
import ProgressBar from "@components/_atoms/progress_bar_reg"

const InvestEmploymentPage = () => {
  const { employment } = data
  return (
    <Layout pageClass="pledge" type="gray">
      <SEO title={employment.title} />
      <ProgressBar />
      <BackButton data={employment.backLink} />
      <HeaderAndText data={employment.heading} />
      <EmploymentForm />
    </Layout>
  )
}

export default InvestEmploymentPage
