import React from "react"
import "./style.scss"

const RadioButton = ({ children, data }) => (
  <div className="radio-button">
    <input
      type="radio"
      id={data.id}
      name={data.name}
      value={data.value}
      checked={data.checked}
      defaultChecked={data.defaultChecked}
    />
    <label htmlFor={data.id}>{children}</label>
  </div>
)

export default RadioButton
