import React, { Component } from "react"
import "./style.scss"
import axios from "axios"
import * as headers from "@data/headers"
import FormButton from "@components/_atoms/button_form_input_submit"
import RadioWithCheck from "@components/_atoms/button_radio_checkmark"
import RadioButton from "@components/_atoms/button_radio"
import GeneralSubmitMessage from "@components/_atoms/general_submit_message"
import * as progress from "@services/progress"
import * as errors from "@services/errors"

class EmploymentForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      empStatus: "",
      citizenship: "",
      citizenError: false,
      generalError: null,
      buttonSubmitted: false
    }
    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  componentDidMount() {
    let { employmentStatus } = localStorage
    this.setState({
      empStatus: employmentStatus || ``,
      citizenship: "yes"
    })
  }

  handleChange = event => {
    const target = event.target
    const value = target.value
    const name = target.name
    this.setState({ [name]: value, citizenError: "" })
  }

  handleSubmit = ev => {
    ev.preventDefault()
    let _this = this
    if (this.state.citizenship === "No") {
      this.setState({ citizenError: true })
    }
  }

  errorHandler = err => {
    this.setState({
      buttonSubmitted: false,
      generalError: errors.messaging(err)
    })
  }

  isDisabled = () => {
    if (
      this.state.empStatus === "" ||
      this.state.citizenship === "no" ||
      this.state.citizenship === "" ||
      this.state.buttonSubmitted === true
    ) {
      return true
    } else {
      return false
    }
  }

  render() {
    return (
      <div className="employment-status">
        <form onSubmit={this.handleSubmit} onChange={this.handleChange}>
          <RadioWithCheck
            data={{
              id: "employed",
              name: "empStatus",
              value: "EMPLOYED",
              checked: this.state.empStatus === "EMPLOYED"
            }}
          >
            I am fully employed
          </RadioWithCheck>
          <RadioWithCheck
            data={{
              id: "selfEmployed",
              name: "empStatus",
              value: "SELF_EMPLOYED",
              checked: this.state.empStatus === "SELF_EMPLOYED"
            }}
          >
            I am self-employed
          </RadioWithCheck>
          <RadioWithCheck
            data={{
              id: "notEmployed",
              name: "empStatus",
              value: "UNEMPLOYED",
              checked: this.state.empStatus === "UNEMPLOYED"
            }}
          >
            I am not employed
          </RadioWithCheck>
          <RadioWithCheck
            data={{
              id: "student",
              name: "empStatus",
              value: "STUDENT",
              checked: this.state.empStatus === "STUDENT"
            }}
          >
            I am a student
          </RadioWithCheck>
          <RadioWithCheck
            data={{
              id: "retired",
              name: "empStatus",
              value: "RETIRED",
              checked: this.state.empStatus === "RETIRED"
            }}
          >
            I am retired
          </RadioWithCheck>
          <div className="citizenship">
            <p>I am a US citizen</p>
            <RadioButton
              data={{
                id: "yes",
                name: "citizenship",
                value: "yes",
                checked: this.state.citizenship === "yes"
              }}
            >
              Yes
            </RadioButton>
            <RadioButton
              data={{
                id: "no",
                name: "citizenship",
                value: "no",
                checked: this.state.citizenship === "no"
              }}
            >
              No
            </RadioButton>
          </div>

          <div style={{ width: `100%`, height: `3rem` }}></div>
          <p
            className={this.state.citizenship === "no" ? `has-error` : `hidden`}
          >
            Unable to continue if parent is not a US citizen
          </p>
          <GeneralSubmitMessage error={this.state.generalError} />
          <FormButton isDisabled={this.isDisabled()} />
        </form>
      </div>
    )
  }
}

export default EmploymentForm
